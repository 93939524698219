import { createMuiTheme } from "@material-ui/core/styles"
import blue from "@material-ui/core/colors/blue"

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: blue[200],
        },
    },
    typography: {
        fontFamily: [
            "Roboto",
            "sans-serif",
            "Poppins",
        ].join(","),
        h1: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "4rem",
            fontWeight: 700,
        },
        h2: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "2.8rem",
            linHeight: 1.2,
        },
        body1: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "1rem",
        },
        button: {
            fontFamily: "Poppins, sans-serif",
            fontWeight: 400,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: "none",
                borderRadius: 0,
            },
        }
    },
})

export default theme
