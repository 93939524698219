// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cad-and-3-d-modeling-jsx": () => import("./../../../src/pages/cad-and-3d-modeling.jsx" /* webpackChunkName: "component---src-pages-cad-and-3-d-modeling-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-product-design-jsx": () => import("./../../../src/pages/product-design.jsx" /* webpackChunkName: "component---src-pages-product-design-jsx" */),
  "component---src-pages-product-engineering-jsx": () => import("./../../../src/pages/product-engineering.jsx" /* webpackChunkName: "component---src-pages-product-engineering-jsx" */),
  "component---src-pages-prototyping-jsx": () => import("./../../../src/pages/prototyping.jsx" /* webpackChunkName: "component---src-pages-prototyping-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

